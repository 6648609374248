<template>
  <div style="padding: 20px; background: #FFFFFF">
    <div style="text-align: right; margin-bottom: 20px">
      <el-button type="success" size="small" @click="$router.push('/settings/project/flow/add')">创建数据流向</el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="flowList"
      border
      style="width: 100%">
      <el-table-column
        prop="flowName"
        label="流向名称">
      </el-table-column>
      <el-table-column
        prop="createDate"
        label="创建日期">
      </el-table-column>
      <el-table-column
        prop="creatorName"
        label="创建人">
      </el-table-column>
      <el-table-column
        width="160"
        align="center"
        label="启用">
        <template #default="{ row }">
          <el-switch
            @change="switchFlowStatus(row)"
            v-model="row.isEnable"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        width="160"
        align="center"
        label="操作">
        <template #default="{ row }">
          <el-button @click="$router.push('/settings/project/flow/edit/' + row.id)" size="mini" type="primary">设计</el-button>
          <el-button @click="deleteFlow(row.id)" size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getFlowList, switchEnable, deleteFlow } from '@/api/expandTableFlow'

export default {
  name: 'name',
  data () {
    return ({
      loading: true,
      flowList: []
    })
  },
  mounted () {
    this.fetchDataList()
  },
  methods: {
    async deleteFlow (id) {
      this.loading = true
      const res = await deleteFlow({ canvasId: id })
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.fetchDataList()
      } else {
        this.$message.error(res.msg)
        this.loading = false
      }
    },
    async fetchDataList () {
      this.loading = true
      const res = await getFlowList()
      this.loading = false
      this.flowList = res.data
      console.log(res)
    },
    async switchFlowStatus (row) {
      this.loading = true
      const res = await switchEnable(row)
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.fetchDataList()
      } else {
        this.loading = false
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
